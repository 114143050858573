<template>
  <div class="ibox transactions">
    <div class="ibox-title">
      <h2>{{ $t('admin_eurofiscalis.list_transactions.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listTransactionsLoading ? ' sk-loading' : '')">

      <div v-if="listTransactionsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p>Total Amount: {{ totalAmount }}</p>
      <p>Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="listTransactionsProvider"
          :fields="listTransactionsFields"
          ref="listTransactions">
      </b-table>

    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    
  }
})
export default class ListTransactions extends Vue {

  listTransactionsLoading:boolean = false;

  totalAmount:number = 0;
  numberOfRows:number = 0;

  listTransactionsFields = [
      {
        key: "company.name",
        label: "Company"
      },
      {
        key: "invoice.creationDateTime",
        label: "Date",
        formatter: (value:Date, key:any, item:any) => {
          var d = new Date(value);
          const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
          const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
          const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

          return `${da}-${mo}-${ye}`
        }
      },
      {
        key: "invoice.transaction.amount",
        label: "Amount"
      },
      {
        key: "invoice.transaction.details.method",
        label: "Method"
      },
      {
        key: "invoice.transaction.status",
        label: "Status"
      }
    ];

  mounted() {
    /*
    this.listRequestsFields[1].label = this.$t('company.info.name') as string;
    this.listRequestsFields[2].label = this.$t('company.info.web') as string;
    this.listRequestsFields[3].label = this.$t('company.headers.options') as string;
    */
  }

  listTransactionsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/workers/list-transactions', options).then((response:any) => {
      // We check the additional fields
      if(response.transactions) {  
        // We update the total amount
        this.totalAmount = response.transactions.reduce((acc:number, val:any) => {
          return acc + val.invoice.transaction.amount;
        },0);
        this.numberOfRows = response.transactions.length;
        return response.transactions;
      }
      else {
        return [];
      }
    })
  }
}
</script>