<template>
  <div class="ibox logs">
    <div class="ibox-title">
      <h2>{{ $t('admin_stats.list_logs.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLogsLoading ? ' sk-loading' : '')">

      <div v-if="listLogsLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>
    
      <b-table 
          outlined striped
          :items="listLogsProvider"
          :fields="listLogsFields"
          ref="listLogs">
      </b-table>

      <button class="btn btn-primary" @click="clearLogs()">{{ $t('admin_stats.list_logs.clear-button') }}</button>

    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    
  }
})
export default class ListLogs extends Vue {

  listLogsLoading:boolean = false;

  listLogsFields = [
      {
        key: "dateTime"
      },
      {
        key: "description"
      }
    ];

  mounted() {
    /*
    this.listRequestsFields[1].label = this.$t('company.info.name') as string;
    this.listRequestsFields[2].label = this.$t('company.info.web') as string;
    this.listRequestsFields[3].label = this.$t('company.headers.options') as string;
    */
  }

  listLogsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/stats/logs/list', options).then((response:any) => {
      // We check the additional fields
      if(response.options) {
        
      }
      if(response.logs) {  
        return response.logs;
      }
      else {
        return [];
      }
    })
  }

  clearLogs() {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.postAPI('/api/admin/stats/logs/clear', null, options).then((response:any) => {
      // We check the additional fields
      if(response.cleared) {  
         // @ts-ignore
        this.$refs.listLogs.refresh()
      }
    })
  }
}
</script>